var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('a-tabs',{staticClass:"my-tabs",attrs:{"hide-add":"","type":"editable-card"},on:{"edit":_vm.onEdit,"change":_vm.changeTab},model:{value:(_vm.activeKey),callback:function ($$v) {_vm.activeKey=$$v},expression:"activeKey"}},[_vm._l((_vm.getTabList),function(tab,index){return _c('a-tab-pane',{key:tab.key,attrs:{"closable":_vm.getTabList.length > 1}},[_c('template',{slot:"tab"},[_c('a-dropdown',{attrs:{"trigger":['contextmenu']}},[_c('span',{staticStyle:{"display":"inline-table"}},[_vm._v(_vm._s(tab.title))]),_c('a-menu',{attrs:{"slot":"overlay"},slot:"overlay"},[_c('a-menu-item',{on:{"click":function($event){return _vm.closeTabs({
                key: tab.key,
              })}}},[_c('a-button',{attrs:{"type":"link","disabled":_vm.getTabList.length <= 1}},[_vm._v("关闭其他")])],1),_c('a-menu-item',{on:{"click":function($event){return _vm.closeTabs({
                key: tab.key,
                position: 'left',
              })}}},[_c('a-button',{attrs:{"type":"link","disabled":_vm.getTabList.length <= 1 || index === 0}},[_vm._v("关闭左侧")])],1),_c('a-menu-item',{on:{"click":function($event){return _vm.closeTabs({
                key: tab.key,
                position: 'right',
              })}}},[_c('a-button',{attrs:{"type":"link","disabled":_vm.getTabList.length <= 1 || index === _vm.getTabList.length - 1}},[_vm._v("关闭右侧")])],1)],1)],1)],1)],2)}),_c('template',{slot:"tabBarExtraContent"},[_c('user-header')],1)],2)}
var staticRenderFns = []

export { render, staticRenderFns }